<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms and Conditions</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Service details -->
<div class="service-details ptb-100">
    <div class="container">
        <div class="service-details-content">
            <h4>General Terms and Conditions:</h4>

            <h5>1. Introduction</h5>

            <p>
                These Terms And Conditions contained herein on this webpage
                shall govern your use of this website, NPBridge, accessible at
                https://npbridge.com, including all pages within this website
                (collectively referred to herein below as this “Website”). These
                Terms apply in full force and effect to your use of this
                Website, and by using this Website, you expressly accept all
                terms and conditions contained herein in full. You must not use
                this Website if you have any objection to any of these Website
                Standard Terms And Conditions.
            </p>

            <h5>2. Intellectual Property Rights</h5>
            <p>
                Other than the content you own, which you may have opted to
                include on this Website, or information which is already in
                public domain, under these Terms, NPBridge Solutions Pvt Ltd
                and/or its licensors own all rights to the intellectual property
                and material contained in this Website, and all such rights are
                reserved. You are granted a limited license only, subject to the
                restrictions provided in these Terms, for purposes of viewing
                the material contained on this Website.
            </p>

            <h5>3. Restrictions</h5>

            <p>
                You are expressly and emphatically restricted from all of the
                following: publishing any Website material in any media;
                selling, sublicensing and/or otherwise commercializing any
                Website material; publicly performing and/or showing any Website
                material; using this Website in any way that is, or maybe,
                damaging to this Website; using this Website in any way that
                impacts user access to this Website; using this Website contrary
                to applicable laws and regulations, or in a way that causes, or
                may cause, harm to the Website, or to any person or business
                entity; engaging in any data mining, data harvesting, data
                extracting or any other similar activity in relation to this
                Website, or while using this Website; using this Website to
                engage in any advertising or marketing; Certain areas of this
                Website are restricted from access by you and NPBridge Solutions
                Pvt Ltd may further restrict access by you to any areas of this
                Website, at any time, in its sole and absolute discretion. Any
                user ID and password you may have for this Website are
                confidential, and you must maintain the confidentiality of such
                information.
            </p>

            <h5>4. Your Content</h5>

            <p>
                In these Website Standard Terms And Conditions, “Your Content”
                shall mean any audio, video, text, images, or other material you
                choose to display on this Website. With respect to Your Content,
                by displaying it, you grant NPBridge Solutions Pvt Ltd a
                non-exclusive, worldwide, irrevocable, royalty-free,
                sublicensable license to use, reproduce, adapt, publish,
                translate and distribute it in any and all media. Your Content
                must be your own and must not be infringing on any third party’s
                rights. NPBridge Solutions Pvt Ltd reserves the right to remove
                any of Your Content from this Website at any time and for any
                reason, without notice.
            </p>

            <h5>5. No warranties</h5>

            <p>
                This Website is provided “as is,” with all faults, and NPBridge
                Solutions Pvt Ltd makes no express or implied representations or
                warranties of any kind related to this Website or the materials
                contained on this Website. Additionally, nothing contained on
                this Website shall be construed as providing consult or advice
                to you.
            </p>

            <h5>6. Limitation of liability</h5>
            <p>
                In no event shall NPBridge Solutions Pvt Ltd, nor any of its
                officers, directors and employees, be liable to you for anything
                arising out of or in any way connected with your use of this
                Website, whether such liability is under contract, tort or
                otherwise, and NPBridge Solutions Pvt Ltd, including its
                officers, directors, and employees shall not be liable for any
                indirect, consequential or special liability arising out of or
                in any way related to your use of this Website.
            </p>

            <h5>7. Indemnification</h5>
            <p>
                You hereby indemnify to the fullest extent NPBridge Solutions
                Pvt Ltd from and against any and all liabilities, costs,
                demands, causes of action, damages and expenses (including
                reasonable attorney’s fees) arising out of or in any way related
                to your breach of any of the provisions of these Terms.
            </p>

            <h5>8. Severability</h5>
            <p>
                If any provision of these Terms is found to be unenforceable or
                invalid under any applicable law, such unenforceability or
                invalidity shall not render these Terms unenforceable or invalid
                as a whole, and such provisions shall be deleted without
                affecting the remaining provisions herein.
            </p>

            <h5>9. Variation of Terms</h5>
            <p>
                NPBridge Solutions Pvt Ltd is permitted to revise these Terms at
                any time as it sees fit, and by using this Website, you are
                expected to review such Terms on a regular basis to ensure you
                understand all terms and conditions governing the use of this
                Website.
            </p>
            <h5>10. Assignment</h5>
            <p>
                NPBridge Solutions Pvt Ltd shall be permitted to assign,
                transfer, and subcontract its rights and/or obligations under
                these Terms without any notification or consent required.
                However, you shall not be permitted to assign, transfer, or
                subcontract any of your rights and/or obligations under these
                Terms.
            </p>

            <h5>11. Entire Agreement</h5>
            <p>
                These Terms, including any legal notices and disclaimers
                contained on this Website, constitute the entire agreement
                between NPBridge Solutions Pvt Ltd and you in relation to your
                use of this Website and supersede all prior agreements and
                understandings with respect to the same.
            </p>

            <h5>12. Governing Law & Jurisdiction</h5>
            <p>
                These Terms will be governed by and construed in accordance with
                the laws of the State of Karnataka, India, and you submit to the
                non-exclusive jurisdiction of the state and federal courts
                located in Karnataka, India for the resolution of any disputes.
            </p>

            <p>Contact us at info@npbridge.com</p>
            <p>Last Updated - 18 August, 2023</p>
        </div>
    </div>
</div>
<!-- End Service details -->
