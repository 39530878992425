<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="spacle-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img width="250" [src]="logoSrc" alt="logo" />
                </a>

                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <div class="others-options">
                        <a routerLink="/contact" [class]="headerBtn">
                            <i class="bx bxs-hot"></i>Contact Us<span></span>
                        </a>

                        <!-- <a routerLink="/auth/signin" class="optional-btn">
                            <i class="bx bx-log-in"></i>Log In<span></span>
                        </a> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
