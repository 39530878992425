import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-services-details",
    templateUrl: "./terms-conditions.component.html",
    styleUrls: ["./terms-conditions.component.scss"],
})
export class TermsConditionsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
