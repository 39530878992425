<!-- Start IT Solution Banner Area -->
<div class="is-banner-item">
    <div class="container">
        <div class="is-banner-content">
            <h1>Technology That Means Impact</h1>
            <p>
                We empower the Education and Social sectors with the help of
                tailored AI solutions. Get in touch with our team of domain
                experts to discover the potential for your organisation.
            </p>
            <div class="banner-btn">
                <a
                    [ngxScrollTo]="'#products'"
                    style="cursor: pointer"
                    class="default-btn"
                >
                    <i class="bx bxs-hot"></i>Discover Our Solutions<span
                    ></span>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End IT Solution Banner Area -->

<!-- Start Features-->
<div class="is-features-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number"></div>
                    <h3>GPT-Powered Conversations</h3>
                    <p>
                        Meaningful interactions and quality engagement is the
                        key to inspire action and progress. Harnessing the power
                        of GPT for you to experience impactful conversations.
                    </p>
                    <br />

                    <div class="icon">
                        <i class="bx bx-message-square-dots"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number"></div>
                    <h3>Custom Artificial Intelligence</h3>
                    <p>
                        We understand that every organization is unique! Our AI
                        Solutions are customized to align perfectly with your
                        specific goals and needs.
                    </p>
                    <br />

                    <div class="icon">
                        <i class="bx bx-chip"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number"></div>
                    <h3>
                        Human-Centered <br />
                        Design
                    </h3>
                    <p>
                        We know that technology can often have a high learning
                        curve. Hence, our interfaces are designed to be
                        accessible and intuitive, ensuring you can use them
                        without any hassle.
                    </p>

                    <div class="icon">
                        <i class="bx bx-body"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number"></div>
                    <h3>
                        Turning Data Into <br />
                        Valuable Insights
                    </h3>
                    <p>
                        Rule-based and AI-driven technologies to identify,
                        extract and convert web-available data into meaningful
                        information for organisations.
                    </p>
                    <div class="icon">
                        <i class="bx bx-data"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number"></div>
                    <h3>
                        Efficient Management <br />
                        Of Information
                    </h3>
                    <p>
                        Harnessing the power of custom-made AI technologies to
                        supercharge and streamline information management by
                        tapping into domain-specific data.
                    </p>
                    <div class="icon">
                        <i class="bx bx-slider-alt"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Features -->

<!-- Start IS About Area -->
<!-- <div class="is-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-about-content">
                    <span>NPBridge</span>
                    <h3>
                        At The Forefront Of Technological Innovations For Social
                        Impact
                    </h3>
                    <p>
                        Founded with a passion for harnessing technology for
                        good,
                        <B>NPBridge</B> is a socio-tech startup that specializes
                        in developing accessible AI technology for education and
                        the social sector.
                    </p>
                    <p>
                        Our team of dedicated experts combines technical prowess
                        with a deep understanding of real-world needs to create
                        customised solutions that bridge the gap between
                        technology and meaningful impact.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-about-image">
                    <video autoplay loop style="margin-left: 2rem">
                        <source
                            src="assets/videos/npbridge_about.mp4"
                            type="video/mp4"
                        />
                    </video>
                    <div class="is-about-shape">
                        <img
                            src="assets/img/it-solution/about/vector.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End IS About Area -->

<!-- Start Carousel -->
<section id="products">
    <div class="is-services-area ptb-100">
        <div class="container-fluid">
            <div class="section-title-with-large-box">
                <span>Our Products</span>
                <h2>
                    We Bring Intelligence Into <Br /> The Heart Of Social Change
                </h2>
            </div>
            <div class="is-services-slides owl-carousel owl-theme">
                <div class="is-services-card">
                    <h3>
                        <a target="_blank" href="https://socialprimes.com/"
                            >SocialPrimes-Discover NGOs
                        </a>
                    </h3>
                    <div class="is-services-image">
                        <a target="_blank" href="https://socialprimes.com/">
                            <img
                                src="assets/img/it-solution/services/services2.jpg"
                                alt="image"
                            />
                        </a>
                    </div>
                    <p>
                        Explore a large curated database of NGOs, Foundations,
                        Corporates, Grants and Service Providers, all in one
                        place.
                    </p>
                    <a
                        target="_blank"
                        href="https://socialprimes.com/"
                        class="default-btn"
                        >Explore <span></span
                    ></a>
                </div>
                <div class="is-services-card">
                    <h3>
                        <a
                            target="_blank"
                            href="https://search.socialprimes.com/"
                            >NonProfit Search Engine</a
                        >
                    </h3>
                    <div class="is-services-image">
                        <a
                            target="_blank"
                            href="https://search.socialprimes.com/"
                        >
                            <img
                                src="assets/img/npbridge/Nonprofit_search_engine.png"
                                alt="image"
                            />
                        </a>
                    </div>
                    <p>
                        A specialised search engine designed to help you quickly
                        find details of any organisation and insights tailored
                        to the world of social impact.
                    </p>
                    <a
                        target="_blank"
                        href="https://search.socialprimes.com/"
                        class="default-btn"
                        style=""
                        >Explore <span></span
                    ></a>
                </div>
                <div class="is-services-card">
                    <h3>
                        <a
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.npbridge.grantsapp&hl=en_IN&gl=US&pli=1"
                            >GrantsApp-Get Funds For NGOs</a
                        >
                    </h3>
                    <div class="is-services-image">
                        <a
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.npbridge.grantsapp&hl=en_IN&gl=US&pli=1"
                        >
                            <img
                                src="assets/img/npbridge/GrantsApp.png"
                                alt="image"
                            />
                        </a>
                    </div>
                    <p>
                        An essential tool powered with curated and updated
                        information of all the active Grants available for NGOs
                        and Fundraisers seeking funds for their cause.
                    </p>
                    <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.npbridge.grantsapp&hl=en_IN&gl=US&pli=1"
                        class="default-btn"
                        >Explore <span></span
                    ></a>
                </div>
                <div class="is-services-card">
                    <h3>
                        <a target="_blank" href="https://www.velabot.com"
                            >VELA-Virtual E-Learning Assistant</a
                        >
                    </h3>
                    <div class="is-services-image">
                        <a target="_blank" href="https://www.velabot.com">
                            <img
                                src="assets/img/it-solution/services/services1.jpg"
                                alt="image"
                            />
                        </a>
                    </div>
                    <p>
                        An AI solution that powers online programmes for
                        institutes and teachers and assists learners ensuring
                        success in a novel learning environment.
                    </p>
                    <a
                        target="_blank"
                        href="https://www.velabot.com"
                        class="default-btn"
                        >Explore <span></span
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End IS Services Area -->

<!-- Start IS Expertise Area -->
<div class="is-expertise-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-expertise-image">
                    <img
                        style="border-radius: 15% 25% 15% 20%"
                        src="assets/img/npbridge/donation.png"
                        alt="image"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-expertise-content">
                    <span class="sub-title">Technology For Social Good</span>
                    <h2>
                        First to last technological solution for social good!
                    </h2>
                    <p>
                        NPBridge was founded by alumni of IIT Kanpur, India with
                        experience in philanthropy, startups and technical
                        research. It is a technology company committed to
                        creating a positive impact in the social sector. Our
                        mission is to empower social-sector organisations and
                        academic institutions with the tools they need to
                        achieve their goals and make a meaningful difference in
                        the world.
                    </p>
                    <h2>
                        Solutions that bridge gap between technology and impact
                    </h2>
                    <p>
                        Our products leverage cutting-edge AI technology to
                        cater to our partners in the education as well as the
                        social impact space. Connect with our expert team to
                        discover more about Education AI and Social Impact AI.
                    </p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="expertise-funfacts">
                                <h2>
                                    <span class="odometer" data-count="15"
                                        >00</span
                                    >
                                    <span class="sign-icon">+</span>
                                </h2>
                                <p>Projects Launched</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="expertise-funfacts bg-DEEEFA">
                                <h2>
                                    <span class="odometer" data-count="8"
                                        >L</span
                                    >
                                    <span class="sign-icon">L+</span>
                                </h2>
                                <p>Users Benefitted</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Expertise Area -->

<!-- Start Why choose us -->
<div class="is-case-studies-area ptb-100">
    <div class="container">
        <div
            class="section-title-with-large-box d-flex align-items-center justify-content-between"
        >
            <div class="max-width">
                <h2>
                    We tailor AI solutions to meet the unique needs of
                    educational and social organisations
                </h2>
            </div>
        </div>
        <div class="is-case-studies-list-tabs">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active"
                                id="1-tab"
                                data-bs-toggle="tab"
                                href="#one"
                                role="tab"
                                aria-controls="1"
                            >
                                <div class="number">1</div>
                                Expertise Across Domains
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="2-tab"
                                data-bs-toggle="tab"
                                href="#two"
                                role="tab"
                                aria-controls="2"
                            >
                                <div class="number">2</div>
                                Innovative Possibilities
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="3-tab"
                                data-bs-toggle="tab"
                                href="#three"
                                role="tab"
                                aria-controls="3"
                            >
                                <div class="number">3</div>
                                Bespoke Technological Solutions
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="4-tab"
                                data-bs-toggle="tab"
                                href="#four"
                                role="tab"
                                aria-controls="4"
                            >
                                <div class="number">4</div>
                                Partnership Towards Transformation
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="tab-content" id="myTabContent">
                        <div
                            class="tab-pane fade show active"
                            id="one"
                            role="tabpanel"
                        >
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/it-solution/case-studies/case-studies1.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Our experienced team of AI specialists
                                        and domain experts have a deep
                                        understanding of both the education and
                                        the social sector. This expertise
                                        empowers us to design technological
                                        solutions that are adept at addressing
                                        domain-specific challenges. With
                                        NPBridge, you can be assured of AI
                                        solutions that are crafted for your
                                        unique needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="two" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/it-solution/case-studies/case-studies2.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        Our relentless exploration of new ideas
                                        and technology enable us to push
                                        boundaries of what we can achieve in the
                                        education and the social impact space.
                                        Our innovations ensure that your
                                        organisation stays in the forefront of
                                        progress and impact.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="three" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/it-solution/case-studies/case-studies3.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        We understand that organisations and
                                        their requirements are unique and we
                                        take pride in catering to your
                                        uniqueness. Our solutions are
                                        user-focused and custom-made to
                                        seamlessly align with your goals and
                                        requirements. Your suceess is our
                                        driving force!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="four" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img
                                    src="assets/img/it-solution/case-studies/case-studies4.jpg"
                                    alt="image"
                                />
                                <div class="content">
                                    <p>
                                        When you partner with NPBridge, you
                                        become a part of a bigger community that
                                        is actively shaping the way we create
                                        impact in the future. Our partners from
                                        educational institutes and social-impact
                                        organisations are contributing to this
                                        shift by leveraging technology for
                                        sustainable change.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Why choose us -->

<!-- Start Team Area -->
<section class="team-area pt-100 pb-70">
    <div style="text-align: center" class="pb-70">
        <h1 style="font-weight: 600">Meet Our Team</h1>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img src="assets/img/kushal.jpg" alt="image" />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/kushal-sacheti-5986084/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Kushal Sacheti</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img src="assets/img/ashish.png" alt="image" />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/ashish371/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Ashish Agrawal</h3>
                        <span>Co-Founder & CEO</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img src="assets/img/tiwari.jpg" alt="image" />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/sachchidanandtiwari/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Sachchidanand Tiwari</h3>
                        <span>Member Technical</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img
                            style="max-width: 100%; max-height: 100%"
                            src="assets/img/yogita.jpg"
                            alt="image"
                        />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/yogita-suyal-27514291/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Yogita Suyal</h3>
                        <span>Member - Domain & Outreach</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="justify-content: center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img src="assets/img/raya.jpg" alt="image" />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/raya-das-010546143/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Raya Das</h3>
                        <span>Member - Domain & Research</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img src="assets/img/jahnavi.jpg" alt="image" />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/jahnavi-raj-ab5809194/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Jahnavi Raj</h3>
                        <span>Member Technical</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img src="assets/img/pooja1.jpg" alt="image" />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/pooja-patil-34a821171/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Pooja Patil</h3>
                        <span>Member Technical</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img
                            style="max-width: 100%; max-height: 100%"
                            src="assets/img/npbridge/arvind.jpg"
                            alt="image"
                        />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/arvikot/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Arvind Kothari</h3>
                        <span>Chief Strategy Officer (/f)</span>
                    </div>
                </div>
            </div>
        </div>

        <div style="text-align: center">
            <h2 style="font-weight: 600; margin: 4rem 0">Our Advisors</h2>
        </div>

        <div class="row" style="justify-content: center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img
                            style="max-width: 100%; max-height: 100%"
                            src="assets/img/npbridge/prabhakar.jpg"
                            alt="image"
                        />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/tv-prabhakar-1214472/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Prof. Prabhakar TV</h3>
                        <span>BHU (ex-IIT Kanpur)</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div
                        class="image"
                        style="
                            width: 306px;
                            height: 306px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        "
                    >
                        <img
                            style="max-width: 100%; max-height: 100%"
                            src="assets/img/npbridge/sumit.jpg"
                            alt="image"
                        />

                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/sumit-kalra-97a06933/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Sumit Kalra</h3>
                        <span>IIT Jodhpur</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start IS Analysis Area -->
<div class="is-analysis-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-analysis-image">
                    <img
                        src="assets/img/npbridge/partnership.png"
                        alt="image"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-analysis-content">
                    <h3>
                        Let's find out your organization's needs and explore how
                        our AI solutions can make an impact!
                    </h3>
                    <form id="contactForm" #form="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        required
                                        placeholder="Your Name"
                                        [(ngModel)]="userName"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        required
                                        placeholder="Your Email"
                                        [(ngModel)]="userEmail"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Your Message</label>
                                    <textarea
                                        name="message"
                                        class="form-control"
                                        id="message"
                                        cols="30"
                                        rows="6"
                                        required
                                        placeholder="Your Message"
                                        [(ngModel)]="emailMessage"
                                    ></textarea>
                                </div>
                            </div>
                            <div class="contact-info-content">
                                <h4
                                    style="color: #13c4a1"
                                    [innerHTML]="emailResponse"
                                ></h4>
                                <h4
                                    style="font-size: 0.875em; color: #dc3545"
                                    [innerHTML]="errorMessage"
                                ></h4>
                            </div>
                            <div class="mb-2 col-lg-12 col-md-12">
                                <re-captcha
                                    id="recaptcha"
                                    name="recaptcha"
                                    #recaptcha="ngModel"
                                    siteKey="6LceFjokAAAAAN0uUjgKcDZHjb_wJsowUT64RTz-"
                                    [(ngModel)]="token"
                                    required
                                    [class.is-invalid]="
                                        recaptcha.invalid &&
                                        (recaptcha.dirty || recaptcha.touched)
                                    "
                                ></re-captcha>
                                <div
                                    *ngIf="
                                        recaptcha.invalid &&
                                        (recaptcha.dirty || recaptcha.touched)
                                    "
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="recaptcha.errors?.['required']">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn"
                                    (click)="send(form)"
                                >
                                    Let’s Get Started<span></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Analysis Area -->

<!-- Start SM Subscribe Area -->
<div class="sm-subscribe-area ptb-100">
    <div class="container">
        <div class="sm-subscribe-inner-box ptb-100">
            <div class="section-title-with-large-box">
                <h2>
                    Join our growing community for all the latest in the
                    industry!
                </h2>
            </div>
            <form
                class="newsletter-form"
                id="contactForm"
                #form="ngForm"
                data-bs-toggle="validator"
            >
                <div class="form-group">
                    <input
                        type="email"
                        class="input-newsletter"
                        placeholder="Your Email"
                        name="EMAIL"
                        required
                        [(ngModel)]="subscribeUserEmail"
                    />
                </div>
                <button
                    type="submit"
                    class="disabled"
                    style="pointer-events: all; cursor: pointer"
                    (click)="subscribeSend(form)"
                >
                    <i class="bx bx-send"></i>
                </button>
                <div class="contact-info-content">
                    <h4
                        style="color: #13c4a1; padding-top: 2rem"
                        [innerHTML]="subscribeEmailResponse"
                    ></h4>
                    <h4
                        style="
                            font-size: 0.875em;
                            padding-top: 2rem;
                            color: #dc3545;
                        "
                        [innerHTML]="subscribeErrorMessage"
                    ></h4>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End SM Subscribe Area -->
