import {
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
    RECAPTCHA_SETTINGS,
    RecaptchaFormsModule,
    RecaptchaModule,
    RecaptchaSettings,
} from "ng-recaptcha";
import { GoogleMapsModule } from "@angular/google-maps";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layout/preloader/preloader.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { NpbridgeHomeComponent } from "./components/pages/npbridge-home/npbridge-home.component";
import { environment } from "src/environments/environment.prod";
import { NpbridgeContactComponent } from "./components/pages/npbridge-contact/npbridge-contact.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HeaderComponent,
        FooterComponent,
        NpbridgeHomeComponent,
        NpbridgeContactComponent,
        TermsConditionsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgxGoogleAnalyticsModule.forRoot(environment.ga),
        NgxGoogleAnalyticsRouterModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        AppRoutingModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ScrollToModule.forRoot(),
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
