import { Component, OnInit, HostListener } from "@angular/core";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    constructor() {}

    ngOnInit() {}

    light_logo = "assets/img/npbridge_white.svg";
    dark_logo = "assets/img/npbridge_dark.png";
    defaultBtnClass = "default-btn";
    scrollBtnClass = "default-btn scroll-btn";

    logoSrc = this.light_logo;
    headerBtn = this.defaultBtnClass;
    scrollThreshold = 100;

    @HostListener("window:scroll", [])
    onScroll() {
        if (window.scrollY > this.scrollThreshold) {
            this.logoSrc = this.dark_logo;
            this.headerBtn = this.scrollBtnClass;
        } else {
            this.logoSrc = this.light_logo;
            this.headerBtn = this.defaultBtnClass;
        }
    }
}
