import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import axios from "axios";
import { environment } from "src/environments/environment.prod";

@Component({
    selector: "home-ten",
    templateUrl: "./npbridge-home.component.html",
    styleUrls: ["./npbridge-home.component.scss"],
})
export class NpbridgeHomeComponent implements OnInit {
    token: string | undefined;

    constructor() {
        this.token = undefined;
    }

    public send(form: NgForm): void {
        if (form.invalid) {
            for (const control of Object.keys(form.controls)) {
                form.controls[control].markAsTouched();
            }
            return;
        }
        this.submitForm();
    }

    public subscribeSend(form: NgForm): void {
        for (const control of Object.keys(form.controls)) {
            form.controls[control].markAsTouched();
        }
        this.subscribeSubmitForm();
    }

    userName: string = "";
    userEmail: string = "";
    emailMessage: string = "";
    emailResponse = "";
    errorMessage = "";

    subscribeUserEmail: string = "";
    subscribeErrorMessage = "";
    subscribeEmailResponse = "";

    subscribeValidations() {
        var validMessage = {
            status: true,
            message: "",
        };
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.subscribeUserEmail.match(mailformat)) {
            validMessage.status = false;
            validMessage.message = "Please enter a valid email address.";
            return validMessage;
        }
        return validMessage;
    }

    validations() {
        var validMessage = {
            status: true,
            message: "",
        };
        if (!this.userName) {
            validMessage.status = false;
            validMessage.message = "Name cannot be empty.";
            return validMessage;
        }
        if (!this.userEmail) {
            validMessage.status = false;
            validMessage.message = "Email cannot be empty.";
            return validMessage;
        }

        if (!this.emailMessage) {
            validMessage.status = false;
            validMessage.message = "Message cannot be empty.";
            return validMessage;
        }
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.userEmail.match(mailformat)) {
            validMessage.status = false;
            validMessage.message = "Please enter a valid email address.";
            return validMessage;
        }

        return validMessage;
    }

    subscribeSubmitForm() {
        this.subscribeErrorMessage = "";
        this.subscribeEmailResponse = "";
        const validated = this.subscribeValidations();
        if (validated.status) {
            const postData = {
                sendToEmail: environment.sendToEmail,
                userEmail: this.subscribeUserEmail,
                subject: `SUBSCRIBE FORM- ${this.subscribeUserEmail}`,
                message: "User wants to subscribe! Yay!!",
            };
            axios
                .post(`${environment.backendApi}/contact-submit`, postData)
                .then((res) => {
                    this.subscribeUserEmail = "";
                    if ((res.status = 200))
                        this.subscribeEmailResponse =
                            "Subscribed successfully!";
                    else
                        this.subscribeErrorMessage =
                            "Error in subscribing right now. Please send an email on info@npbridge.com";
                })
                .catch((err) => {
                    this.errorMessage =
                        "Error in subscribing right now. Please send an email on info@npbridge.com";
                });
        } else {
            this.subscribeErrorMessage = validated.message;
        }
    }

    submitForm() {
        this.errorMessage = "";
        this.emailResponse = "";
        const validated = this.validations();
        if (validated.status) {
            const postData = {
                sendToEmail: environment.sendToEmail,
                userName: this.userName,
                userEmail: this.userEmail,
                subject: `PARTNER FORM`,
                message: this.emailMessage,
            };
            axios
                .post(`${environment.backendApi}/contact-submit`, postData)
                .then((res) => {
                    this.userEmail = "";
                    this.userName = "";
                    this.emailMessage = "";
                    if ((res.status = 200))
                        this.emailResponse = "Message sent successfully!";
                    else
                        this.errorMessage =
                            "Message could not be sent right now. Please send an email on info@npbridge.com";
                })
                .catch((err) => {
                    this.errorMessage =
                        "Message could not be sent right now. Please send an email on info@npbridge.com";
                });
        } else {
            this.errorMessage = validated.message;
        }
    }

    ngOnInit(): void {}
}
