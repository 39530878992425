<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{
        'd-none':
            router.url === '/home-nine' ||
            router.url === '/home-ten' ||
            router.url === '/home-eleven'
    }"
>
    <div class="divider"></div>
    <div class="container">
        <div class="row" style="justify-content: space-between">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img
                                width="200px"
                                src="assets/img/npbridge_white.svg"
                                alt="logo"
                            />
                        </a>
                    </div>
                    <p>
                        NPBridge is a socio-tech startup organisation based out
                        of Bangalore, India. Our team of domain experts and AI
                        specialists work at the intersection of technology and
                        social good. Our cutting-edge technology is geared
                        towards solving some of the crucial challenges around
                        access to information in both the social and the
                        education sector.
                    </p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a routerLink="/terms">Terms & Conditions</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            Location:
                            <a
                                href="https://goo.gl/maps/WkDg3ZHA8sZ2Z58x8"
                                target="_blank"
                                >HSR Layout, Bangalore, India</a
                            >
                        </li>
                        <li>
                            Email:
                            <a href="mailto:info@npbridge.com"
                                >info@npbridge.com</a
                            >
                        </li>
                        <li>
                            Phone:
                            <a href="tel:+918041109938">+91 804 110 9938</a>
                        </li>
                    </ul>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li> -->
                        <li>
                            <a
                                href="https://www.linkedin.com/company/npbridge/"
                                target="_blank"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="35"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.twitter.com/npbridge/"
                                target="_blank"
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="35"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584l-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
                                    />
                                </svg>
                            </a>
                        </li>
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright @{{ currentYear }} NPBridge.</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class="bx bx-chevron-up"></i></div>
