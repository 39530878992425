import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NpbridgeContactComponent } from "./components/pages/npbridge-contact/npbridge-contact.component";
import { NpbridgeHomeComponent } from "./components/pages/npbridge-home/npbridge-home.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";

const routes: Routes = [
    { path: "", component: NpbridgeHomeComponent },
    { path: "contact", component: NpbridgeContactComponent },
    { path: "terms", component: TermsConditionsComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            scrollPositionRestoration: "top",
            onSameUrlNavigation: "reload",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
