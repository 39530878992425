export const environment = {
    production: true,
    ga: "G-X64XTYX7RM",
    sendToEmail: "contact@velabot.com",
    backendApi: "https://velabot.com/velabot-api",
    recaptcha: {
        siteKey: "6LceFjokAAAAAN0uUjgKcDZHjb_wJsowUT64RTz-",
    },
    mapsApi: "AIzaSyDbzrNUTQXWTv3QkOJ3jF5i6ZSbTkmwN54",
};
